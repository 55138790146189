.ql-snow .ql-editor, div[data-class="quill-text"] {
  font-family: PoppinsRegular, sans-serif;
}

.ql-snow .ql-editor h1, div[data-class="quill-text"] h1 {
  font-size: 36px;
  font-family: PoppinsSemiBold, sans-serif;
  margin-bottom: 0.5rem;
  line-height: 132%;
}
.ql-snow .ql-editor h2, div[data-class="quill-text"] h2 {
  font-size: 32px;
  font-family: PoppinsMedium, sans-serif;
  margin-bottom: 0.5rem;
  line-height: 130%;
}
.ql-snow .ql-editor h3, div[data-class="quill-text"] h3 {
  font-size: 26px;
  font-family: PoppinsMedium, sans-serif;
  margin-bottom: 0.5rem;
  line-height: 125%;
}
.ql-snow .ql-editor h4, div[data-class="quill-text"] h4 {
  font-size: 24px;
  font-family: PoppinsMedium, sans-serif;
  margin-bottom: 0.5rem;
  line-height: 125%;
}
.ql-snow .ql-editor h5, div[data-class="quill-text"] h5 {
  font-size: 20px;
  font-family: PoppinsMedium, sans-serif;
  margin-bottom: 0.5rem;
  line-height: 125%;
}
.ql-snow .ql-editor p, div[data-class="quill-text"] p {
  font-size: 16px;
  font-family: PoppinsRegular, sans-serif;
  line-height: 132%;
  margin-bottom: 1rem;
}
